import { supabase } from '../supabase/config';
import { logError, logInfo } from '../utils/logger';
import { AuthError, AUTH_ERROR_CODES } from './errors';
import type { AuthUser, AuthResponse } from './types';

export const authService = {
  login: async (email: string, password: string): Promise<AuthResponse> => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        if (error.message.includes('Invalid login credentials')) {
          throw new AuthError(
            'Credenciales inválidas',
            AUTH_ERROR_CODES.INVALID_CREDENTIALS
          );
        }
        throw error;
      }

      if (!data.user) {
        throw new AuthError(
          'No se pudo iniciar sesión',
          AUTH_ERROR_CODES.UNAUTHORIZED
        );
      }

      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', data.user.id)
        .single();

      const user: AuthUser = {
        id: data.user.id,
        email: data.user.email,
        name: profile?.name || data.user.user_metadata?.name || null,
        role: profile?.role || 'user',
        createdAt: data.user.created_at
      };

      logInfo('Usuario autenticado correctamente', { email });
      return { user, error: null };
    } catch (error) {
      logError(error as Error, { context: 'Auth Service - Login' });
      return { 
        user: null, 
        error: error as AuthError 
      };
    }
  },

  loginWithGoogle: async (): Promise<AuthResponse> => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent'
          }
        }
      });

      if (error) throw error;

      // Note: The actual user data will be handled by the auth state change listener
      // since this is a redirect-based flow
      return { user: null, error: null };
    } catch (error) {
      logError(error as Error, { context: 'Auth Service - Google Login' });
      return {
        user: null,
        error: new AuthError(
          'Error al iniciar sesión con Google',
          AUTH_ERROR_CODES.UNAUTHORIZED
        )
      };
    }
  },

  register: async (email: string, password: string, name: string): Promise<AuthResponse> => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { name },
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        if (error.message.includes('password')) {
          throw new AuthError(
            'La contraseña no cumple con los requisitos de seguridad',
            AUTH_ERROR_CODES.WEAK_PASSWORD
          );
        }
        throw error;
      }

      if (!data.user) {
        throw new AuthError(
          'No se pudo crear la cuenta',
          AUTH_ERROR_CODES.UNAUTHORIZED
        );
      }

      logInfo('Usuario registrado correctamente', { email });
      return { 
        user: {
          id: data.user.id,
          email: data.user.email,
          name,
          role: 'user',
          createdAt: data.user.created_at
        }, 
        error: null 
      };
    } catch (error) {
      logError(error as Error, { context: 'Auth Service - Register' });
      return { 
        user: null, 
        error: error as AuthError 
      };
    }
  },

  logout: async (): Promise<{ error: AuthError | null }> => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      logInfo('Usuario cerró sesión correctamente');
      return { error: null };
    } catch (error) {
      logError(error as Error, { context: 'Auth Service - Logout' });
      return { 
        error: new AuthError(
          'Error al cerrar sesión',
          AUTH_ERROR_CODES.UNAUTHORIZED
        )
      };
    }
  }
};
import { createClient } from '@supabase/supabase-js';
import { logError, logInfo, logWarning } from '../utils/logger';
import type { Database } from './types';

// Validate environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  logWarning('Missing Supabase credentials');
  throw new Error('Missing Supabase credentials. Please check your environment variables.');
}

// Create Supabase client with Google OAuth config
export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    providers: ['google'],
    storage: window.localStorage,
    storageKey: 'supabase.auth.token',
    debug: import.meta.env.DEV,
    cookieOptions: {
      name: 'sb-auth-token',
      lifetime: 60 * 60 * 24 * 7, // 7 days
      domain: window.location.hostname,
      path: '/',
      sameSite: 'lax'
    }
  },
  global: {
    headers: {
      'x-application-name': 'pedro-palomares'
    }
  }
});

// Initialize Supabase with retries
let connectionAttempts = 0;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const initSupabase = async () => {
  try {
    // Simple health check that doesn't require auth
    const { error } = await supabase.from('profiles').select('id').limit(1);

    // If we get a permission error, that's actually good - it means Supabase is responding
    if (error?.code === 'PGRST116' || error?.code === '42501') {
      logInfo('Supabase connection verified (limited access)');
      return true;
    }

    if (error) throw error;

    logInfo('Supabase connection verified successfully');
    return true;
  } catch (error) {
    logError(error as Error, { 
      context: 'Supabase Initialization',
      attempt: connectionAttempts + 1,
      maxRetries: MAX_RETRIES
    });

    if (connectionAttempts < MAX_RETRIES) {
      connectionAttempts++;
      logInfo(`Retrying Supabase connection (attempt ${connectionAttempts}/${MAX_RETRIES})`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return initSupabase();
    }

    return false;
  }
};

export const checkSupabaseConnection = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      logWarning('Supabase session check failed', { error: error.message });
      return false;
    }
    return true;
  } catch (error) {
    logError(error as Error, { context: 'Supabase Connection Check' });
    return false;
  }
};
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../../lib/supabase/config';
import { logInfo, logError } from '../../lib/utils/logger';
import { toast } from 'react-hot-toast';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get session from URL
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          throw sessionError;
        }

        if (session) {
          logInfo('User authenticated successfully', { userId: session.user.id });
          
          // Get redirect destination from URL params or default to dashboard
          const redirectTo = searchParams.get('redirectTo') || '/dashboard';
          navigate(redirectTo, { replace: true });
          
          toast.success('¡Inicio de sesión exitoso!');
        } else {
          throw new Error('No se encontró sesión de usuario');
        }
      } catch (error) {
        logError(error as Error, { context: 'Auth Callback' });
        toast.error('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
        navigate('/login', { replace: true });
      }
    };

    handleCallback();
  }, [navigate, searchParams]);

  return (
    <div className="min-h-screen bg-dark flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
};

export default AuthCallback;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabase/config';
import { authService } from './service';
import { logInfo, logError } from '../utils/logger';
import { toast } from 'react-hot-toast';
import { AuthError, AUTH_ERROR_CODES } from './errors';
import type { AuthContextType, AuthState, AuthUser } from './types';

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null
  });

  useEffect(() => {
    const initAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) throw error;
        
        if (session?.user) {
          await updateUserData(session.user);
        }
      } catch (error) {
        logError(error as Error, { context: 'Auth Initialization' });
      } finally {
        setState(prev => ({ ...prev, loading: false }));
      }
    };

    initAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      logInfo('Auth state changed', { event });
      
      if (session?.user) {
        await updateUserData(session.user);
      } else {
        setState(prev => ({ ...prev, user: null, loading: false }));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const updateUserData = async (authUser: any) => {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', authUser.id)
        .single();

      if (error) throw error;

      setState(prev => ({
        ...prev,
        user: {
          id: authUser.id,
          email: authUser.email,
          name: profile?.name || authUser.user_metadata?.name || null,
          role: profile?.role || 'user',
          createdAt: authUser.created_at
        },
        loading: false,
        error: null
      }));
    } catch (error) {
      logError(error as Error, { context: 'Update User Data', userId: authUser.id });
      setState(prev => ({ 
        ...prev, 
        loading: false,
        error: 'Error al cargar datos del usuario'
      }));
    }
  };

  const handleAuthError = (error: unknown) => {
    let message = 'Error en la autenticación';

    if (error instanceof AuthError) {
      switch (error.code) {
        case AUTH_ERROR_CODES.INVALID_CREDENTIALS:
          message = 'Email o contraseña incorrectos';
          break;
        case AUTH_ERROR_CODES.WEAK_PASSWORD:
          message = 'La contraseña debe cumplir con todos los requisitos de seguridad';
          break;
        case AUTH_ERROR_CODES.EMAIL_IN_USE:
          message = 'Este email ya está registrado';
          break;
        case AUTH_ERROR_CODES.SESSION_EXPIRED:
          message = 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.';
          break;
        case AUTH_ERROR_CODES.NETWORK_ERROR:
          message = 'Error de conexión. Por favor, verifica tu red.';
          break;
        default:
          if (error instanceof Error) {
            message = error.message;
          }
      }
    }

    toast.error(message);
    setState(prev => ({ ...prev, error: message }));
  };

  const login = async (email: string, password: string) => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { user, error } = await authService.login(email, password);
      
      if (error) throw error;
      if (!user) throw new Error('No se pudo iniciar sesión');

      toast.success('¡Bienvenido de nuevo!');
    } catch (error) {
      handleAuthError(error);
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const loginWithGoogle = async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { user, error } = await authService.loginWithGoogle();
      
      if (error) throw error;
      if (!user) throw new Error('No se pudo iniciar sesión con Google');

      toast.success('¡Bienvenido!');
    } catch (error) {
      handleAuthError(error);
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const register = async (email: string, password: string, name: string) => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { user, error } = await authService.register(email, password, name);
      
      if (error) throw error;
      if (!user) throw new Error('No se pudo crear la cuenta');

      toast.success('¡Cuenta creada exitosamente! Por favor, inicia sesión.');
    } catch (error) {
      handleAuthError(error);
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const logout = async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { error } = await authService.logout();
      
      if (error) throw error;
      
      setState({ user: null, loading: false, error: null });
      toast.success('¡Hasta pronto!');
    } catch (error) {
      handleAuthError(error);
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const value = {
    ...state,
    login,
    loginWithGoogle,
    register,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext }
import React from 'react';
import SEO from '../components/SEO';

const Cookies = () => {
  return (
    <>
      <SEO 
        title="Política de Cookies - Pedro Palomares Digital Coach"
        description="Información sobre el uso de cookies en nuestro sitio web"
      />
      
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold mb-8">Política de Cookies</h1>
        
        <div className="prose prose-invert">
          <h2>1. ¿Qué son las cookies?</h2>
          <p>
            Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web.
          </p>

          <h2>2. Tipos de Cookies que Utilizamos</h2>
          
          <h3>Cookies Esenciales</h3>
          <p>
            Necesarias para el funcionamiento básico del sitio web. No pueden ser desactivadas.
          </p>
          
          <h3>Cookies de Rendimiento</h3>
          <p>
            Nos ayudan a entender cómo interactúas con el sitio web y mejorar su funcionamiento.
          </p>
          
          <h3>Cookies de Funcionalidad</h3>
          <p>
            Permiten recordar tus preferencias y personalizar tu experiencia.
          </p>
          
          <h3>Cookies de Marketing</h3>
          <p>
            Utilizadas para mostrarte publicidad relevante basada en tus intereses.
          </p>

          <h2>3. Control de Cookies</h2>
          <p>
            Puedes gestionar tus preferencias de cookies en cualquier momento a través del banner de cookies.
            También puedes configurar tu navegador para rechazar todas las cookies o indicarte cuando se envía una cookie.
          </p>

          <h2>4. Cookies de Terceros</h2>
          <p>
            Utilizamos servicios de terceros que pueden establecer sus propias cookies:
          </p>
          <ul>
            <li>Google Analytics (análisis de uso)</li>
            <li>Supabase (autenticación)</li>
          </ul>

          <h2>5. Actualizaciones</h2>
          <p>
            Esta política puede ser actualizada ocasionalmente. La última actualización fue realizada el 19 de marzo de 2024.
          </p>
        </div>
      </div>
    </>
  );
};

export default Cookies;
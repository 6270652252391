import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const Privacy = () => {
  return (
    <>
      <SEO 
        title="Política de Privacidad - Pedro Palomares Digital Coach"
        description="Política de privacidad y protección de datos personales"
      />
      
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold mb-8">Política de Privacidad</h1>
        
        <div className="prose prose-invert">
          <h2>1. Información que Recopilamos</h2>
          <p>
            Recopilamos la siguiente información personal:
          </p>
          <ul>
            <li>Nombre y apellidos</li>
            <li>Dirección de correo electrónico</li>
            <li>Información de uso del sitio web</li>
          </ul>

          <h2>2. Cómo Utilizamos tu Información</h2>
          <p>
            Utilizamos tu información personal para:
          </p>
          <ul>
            <li>Proporcionar nuestros servicios</li>
            <li>Comunicarnos contigo</li>
            <li>Mejorar nuestros servicios</li>
            <li>Cumplir con obligaciones legales</li>
          </ul>

          <h2>3. Tus Derechos GDPR</h2>
          <p>
            Tienes los siguientes derechos sobre tus datos personales:
          </p>
          <ul>
            <li>Derecho de acceso</li>
            <li>Derecho de rectificación</li>
            <li>Derecho de supresión</li>
            <li>Derecho a la limitación del tratamiento</li>
            <li>Derecho a la portabilidad de los datos</li>
            <li>Derecho de oposición</li>
          </ul>

          <p>
            Para ejercer tu derecho de supresión, puedes utilizar nuestro{' '}
            <Link to="/data-deletion" className="text-primary hover:text-primary-light">
              formulario de eliminación de datos
            </Link>.
          </p>

          <h2>4. Seguridad de los Datos</h2>
          <p>
            Implementamos medidas de seguridad técnicas y organizativas apropiadas para proteger tus datos personales.
          </p>

          <h2>5. Contacto</h2>
          <p>
            Para ejercer tus derechos o realizar consultas sobre privacidad, contáctanos en:
            <br />
            Email: privacy@pedropalomares.com
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
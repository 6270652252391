import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate } from 'react-router-dom';
import { Mail, Lock, User, Loader2, AlertCircle, Info } from 'lucide-react';
import { registerSchema, type RegisterForm } from '../lib/auth/validation';
import { useAuth } from '../lib/auth/hooks/useAuth';
import { trackEvent } from '../lib/analytics';
import SEO from '../components/SEO';
import AuthWrapper from '../components/auth/AuthWrapper';

const Signup = () => {
  const navigate = useNavigate();
  const { register } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const { register: registerField, handleSubmit, formState: { errors } } = useForm<RegisterForm>({
    resolver: zodResolver(registerSchema)
  });

  const onSubmit = async (data: RegisterForm) => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    setError(null);

    try {
      await register(data.email, data.password, data.name);
      trackEvent('Auth', 'Register', 'Success');
      navigate('/login', { replace: true });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Error al crear la cuenta';
      setError(message);
      trackEvent('Auth', 'Register', 'Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthWrapper>
      <SEO 
        title="Registro - Pedro Palomares Digital Coach"
        description="Crea tu cuenta para acceder a recursos exclusivos y consultas personalizadas."
      />
      
      <h1 className="text-2xl font-bold text-white mb-6 text-center">
        Crear Cuenta
      </h1>

      {error && (
        <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center text-red-500">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="mb-6 p-4 bg-primary/10 border border-primary rounded-lg">
        <h3 className="flex items-center text-primary font-medium mb-2">
          <Info className="h-5 w-5 mr-2" />
          Requisitos de contraseña
        </h3>
        <ul className="text-sm text-gray-400 space-y-1 list-disc list-inside">
          <li>Al menos 8 caracteres</li>
          <li>Al menos una letra minúscula</li>
          <li>Al menos una letra mayúscula</li>
          <li>Al menos un número</li>
          <li>Al menos un carácter especial (!@#$%^&*)</li>
        </ul>
      </div>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
            Nombre
          </label>
          <div className="relative">
            <User className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              {...registerField('name')}
              type="text"
              id="name"
              className="w-full pl-10 pr-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
              placeholder="Tu nombre completo"
            />
          </div>
          {errors.name && (
            <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
            Email
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              {...registerField('email')}
              type="email"
              id="email"
              className="w-full pl-10 pr-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
              placeholder="tu@email.com"
            />
          </div>
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
            Contraseña
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              {...registerField('password')}
              type="password"
              id="password"
              className="w-full pl-10 pr-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
              placeholder="********"
            />
          </div>
          {errors.password && (
            <p className="mt-1 text-sm text-red-500">{errors.password.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300 mb-1">
            Confirmar Contraseña
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              {...registerField('confirmPassword')}
              type="password"
              id="confirmPassword"
              className="w-full pl-10 pr-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
              placeholder="********"
            />
          </div>
          {errors.confirmPassword && (
            <p className="mt-1 text-sm text-red-500">{errors.confirmPassword.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex items-center justify-center px-6 py-3 bg-primary text-white rounded-md hover:bg-primary-light transition-colors disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="animate-spin h-5 w-5 mr-2" />
              Creando cuenta...
            </>
          ) : (
            'Crear Cuenta'
          )}
        </button>

        <p className="mt-6 text-center text-gray-400">
          ¿Ya tienes una cuenta?{' '}
          <Link to="/login" className="text-primary hover:text-primary-light">
            Inicia sesión aquí
          </Link>
        </p>
      </form>
    </AuthWrapper>
  );
};

export default Signup;
import React from 'react';
import { Mail, AlertTriangle, CheckCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from 'react-hot-toast';
import SEO from '../components/SEO';

const deletionSchema = z.object({
  email: z.string().email('Email inválido'),
  reason: z.string().min(10, 'Por favor, proporciona más detalles sobre tu solicitud'),
  confirm: z.literal(true, {
    errorMap: () => ({ message: 'Debes confirmar que entiendes las implicaciones' })
  })
});

type DeletionForm = z.infer<typeof deletionSchema>;

const DataDeletion = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<DeletionForm>({
    resolver: zodResolver(deletionSchema)
  });

  const onSubmit = async (data: DeletionForm) => {
    try {
      // Aquí se enviaría la solicitud al backend
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulación
      toast.success('Solicitud enviada correctamente');
    } catch (error) {
      toast.error('Error al enviar la solicitud');
    }
  };

  return (
    <>
      <SEO 
        title="Eliminación de Datos - Pedro Palomares Digital Coach"
        description="Solicita la eliminación de tus datos personales de nuestra plataforma"
      />
      
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold mb-8">Eliminación de Datos</h1>
        
        <div className="prose prose-invert mb-8">
          <p className="text-lg">
            En cumplimiento con el RGPD (Reglamento General de Protección de Datos), 
            proporcionamos un proceso sencillo para solicitar la eliminación de tus datos personales 
            de nuestra plataforma.
          </p>
        </div>

        <div className="bg-dark-lighter rounded-lg p-6 mb-8 border border-gray-800">
          <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
            <AlertTriangle className="h-5 w-5 mr-2" />
            Información Importante
          </h2>
          <ul className="space-y-2 text-gray-300">
            <li className="flex items-start">
              <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0 text-primary mt-0.5" />
              Tu solicitud será procesada en un plazo máximo de 30 días.
            </li>
            <li className="flex items-start">
              <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0 text-primary mt-0.5" />
              La eliminación de datos es permanente y no se puede deshacer.
            </li>
            <li className="flex items-start">
              <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0 text-primary mt-0.5" />
              Se eliminarán todos tus datos personales, incluyendo historial de actividad y preferencias.
            </li>
            <li className="flex items-start">
              <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0 text-primary mt-0.5" />
              Algunos datos pueden ser retenidos por obligaciones legales o fiscales.
            </li>
          </ul>
        </div>

        <div className="bg-dark-lighter rounded-lg p-6 border border-gray-800">
          <h2 className="text-xl font-semibold mb-6">Solicitud de Eliminación</h2>
          
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                Email
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  {...register('email')}
                  type="email"
                  id="email"
                  className="w-full pl-10 pr-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
                  placeholder="tu@email.com"
                />
              </div>
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="reason" className="block text-sm font-medium text-gray-300 mb-1">
                Motivo de la Solicitud
              </label>
              <textarea
                {...register('reason')}
                id="reason"
                rows={4}
                className="w-full px-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
                placeholder="Por favor, explica por qué deseas eliminar tus datos"
              />
              {errors.reason && (
                <p className="mt-1 text-sm text-red-500">{errors.reason.message}</p>
              )}
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  {...register('confirm')}
                  type="checkbox"
                  id="confirm"
                  className="h-4 w-4 rounded border-gray-700 bg-dark text-primary focus:ring-primary"
                />
              </div>
              <label htmlFor="confirm" className="ml-2 block text-sm text-gray-300">
                Entiendo que esta acción es permanente y no se puede deshacer
              </label>
            </div>
            {errors.confirm && (
              <p className="text-sm text-red-500">{errors.confirm.message}</p>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex items-center justify-center px-6 py-3 bg-primary text-white rounded-md hover:bg-primary-light transition-colors disabled:opacity-50"
            >
              {isSubmitting ? (
                <>
                  <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                  Enviando solicitud...
                </>
              ) : (
                'Enviar Solicitud de Eliminación'
              )}
            </button>
          </form>
        </div>

        <div className="mt-8 text-gray-400 text-sm">
          <p>
            Para cualquier duda sobre el proceso de eliminación de datos, puedes contactarnos en{' '}
            <a href="mailto:privacy@pedropalomares.com" className="text-primary hover:text-primary-light">
              privacy@pedropalomares.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default DataDeletion;
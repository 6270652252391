import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import AdminLayout from './components/admin/AdminLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import ProtectedRoute from './components/auth/ProtectedRoute';
import CookieConsent from './components/compliance/CookieConsent';
import SkipLink from './components/compliance/SkipLink';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';
import Legal from './pages/Legal';
import DataDeletion from './pages/DataDeletion';
import AdminDashboard from './pages/admin/Dashboard';
import BlogAdmin from './pages/admin/blog';
import DashboardHome from './pages/dashboard/Home';
import Automations from './pages/dashboard/Automations';
import ApiKeys from './pages/dashboard/ApiKeys';
import Settings from './pages/dashboard/Settings';
import AuthCallback from './components/auth/AuthCallback';

const App = () => {
  return (
    <>
      <SkipLink />
      <CookieConsent />
      
      <Routes>
        {/* Public routes with main layout */}
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/data-deletion" element={<DataDeletion />} />
        </Route>

        {/* Auth routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/auth/callback" element={<AuthCallback />} />

        {/* Dashboard routes */}
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <DashboardLayout>
              <DashboardHome />
            </DashboardLayout>
          </ProtectedRoute>
        } />
        
        <Route path="/dashboard/automations" element={
          <ProtectedRoute>
            <DashboardLayout>
              <Automations />
            </DashboardLayout>
          </ProtectedRoute>
        } />

        <Route path="/dashboard/api-keys" element={
          <ProtectedRoute>
            <DashboardLayout>
              <ApiKeys />
            </DashboardLayout>
          </ProtectedRoute>
        } />

        <Route path="/dashboard/settings" element={
          <ProtectedRoute>
            <DashboardLayout>
              <Settings />
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Admin routes */}
        <Route path="/admin" element={
          <ProtectedRoute requiredRole="admin">
            <AdminLayout>
              <AdminDashboard />
            </AdminLayout>
          </ProtectedRoute>
        } />
        
        <Route path="/admin/blog" element={
          <ProtectedRoute requiredRole="admin">
            <AdminLayout>
              <BlogAdmin />
            </AdminLayout>
          </ProtectedRoute>
        } />
      </Routes>
    </>
  );
};

export default App;
import React from 'react';
import SEO from '../components/SEO';

const Legal = () => {
  return (
    <>
      <SEO 
        title="Aviso Legal - Pedro Palomares Digital Coach"
        description="Información legal y términos de uso del sitio web"
      />
      
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold mb-8">Aviso Legal</h1>
        
        <div className="prose prose-invert">
          <h2>1. Información Legal</h2>
          <p>
            En cumplimiento del artículo 10 de la Ley 34/2002, de Servicios de la Sociedad de la Información y Comercio Electrónico, se informa:
          </p>
          <ul>
            <li><strong>Titular:</strong> Alianzas Comerciales 2014 S.L.</li>
            <li><strong>CIF:</strong> B66222811</li>
            <li><strong>Domicilio Social:</strong> Barcelona, España</li>
            <li><strong>Email:</strong> info@pedropalomares.com</li>
            <li><strong>Teléfono:</strong> +34 619 410 431</li>
          </ul>

          <h2>2. Objeto</h2>
          <p>
            El presente aviso legal regula el uso del sitio web pedropalomares.com, del que es titular Alianzas Comerciales 2014 S.L.
          </p>

          <h2>3. Propiedad Intelectual</h2>
          <p>
            Todos los derechos de propiedad intelectual del contenido de esta página web son propiedad de Alianzas Comerciales 2014 S.L.
          </p>

          <h2>4. Responsabilidad</h2>
          <p>
            Alianzas Comerciales 2014 S.L. no se hace responsable de los daños que puedan causarse por interferencias, interrupciones, virus informáticos o desconexiones en el sistema.
          </p>

          <h2>5. Legislación Aplicable</h2>
          <p>
            Este sitio web se rige por la legislación española y europea, específicamente:
          </p>
          <ul>
            <li>RGPD (Reglamento General de Protección de Datos)</li>
            <li>LSSI (Ley de Servicios de la Sociedad de la Información)</li>
            <li>Directiva ePrivacy</li>
          </ul>

          <h2>6. Resolución de Conflictos</h2>
          <p>
            Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes.
          </p>
        </div>
      </div>
    </>
  );
};

export default Legal;